body {
  background: #444;
  font-family: Cairo, Arial, sans-serif;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div.root {
  height: 100vh;
}
