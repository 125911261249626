
button {
  background-color: #444;
  border: 1px solid #aaa;
  border-radius: 0px;
  color: white;
  font-size: 14px;
  padding: 3px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

button:hover {
  background-color: #222;
}

button:disabled {
  opacity: 50%;
  background-color: #444;
}

div {
  -ms-overflow-style: -ms-autohiding-scrollbar;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

div::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

input[type=text], input[type=email], input[type=password] {
  color: white;
  background-color: #222;
  caret-color: white;
  border: none;
}

input[type=password] {
  -webkit-text-security: square;
}

input[type=range] {
  -webkit-appearance: none;
  background: transparent;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 14px;
  width: 13px;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;
  margin-top: -3.5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 12px;
  width: 10px;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 12px;
  width: 10px;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #666;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #666;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #666;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #666;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-lower {
  background: #666;
}

input[type=range]::-ms-fill-upper {
  background: #666;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-upper {
  background: #666;
}

input:focus{
  outline: none;
}

.ActiveTab {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  grid-row: 2;
  overflow: scroll;
  background: #999;
  z-index: 1;
}

.AddAdminButton {
  margin-left: 10px;
}

.AddMusic {
  height: 100%;
}

.AlbumGrid {
  display: grid;
  grid-template-columns: min-content min-content min-content 1fr;
}

.AlbumGroupControlLabel {
  margin-right: 10px;
}

.AlbumGroupControls {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.AlbumLoading {
  height: 200px;
}

.AlbumSeparator {
  margin-left: 10px;
  margin-right: 10px;
}

.AlbumTitle {
  font-weight: bold;
}

.AlbumTrackControls {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.AlbumTrackDuration {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}

.AlbumTrackNumber {
  padding: 5px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AlbumTrackTitle {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.AlbumView {
}

.AlbumViewContent {
  padding: 20px;
  transition: height 0.2s;
}

.AlbumViewHeader {
  margin-top: 20px;
  padding: 2px;
}

.App {
  text-align: center;
  color: white;
  padding: 0;
  height: 100vh;
}

.Button {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.ButtonActive {
  background: #666;
}

.ChatContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
}

.ChatEmpty {
  padding: 15px;
}

.ChatInfo {
  color: #aaa;
}

.ChatLine {
  padding-left: 1em;
  text-indent: -1em;
}

.ChatPage {
  display: grid;
  grid-row: 2;
  grid-template-rows: 1fr min-content;
  background: #222;
  height: 100%;
  overflow: hidden;
}

.ContentBox {
  background: #000044;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
}

.CreateRoomButton {
  margin: 20px;
}

.Dimmer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.EditText {
  width: 100%;
  height: 100%;
}

.EditTextError {
  margin-top: 5px;
  color: red;
}

.EditTextInput {
  border: 1px solid #aaa;
  width: 90%;
}

.EditTextText {
  display: block;
  width: 100%;
  height: 100%;
}

.ErrorMessage {
  color: #ff6666;
  font-weight: bold;
}

.Even {
  background: #333;
}

.FullPadding {
  padding: 20px;
}

.GridLabel {
  text-align: right;
  padding: 10px;
}

.GridSpanLabel {
  background: #444;
  padding: 5px;
  color: #ccc;
}

.GridText {
  text-align: left;
  background: #000;
  padding: 10px;
}

.HalfPadding {
  padding: 10px;
}

.HomeTitle {
}

.InactiveTab {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.InvitationsActiveGrid {
  display: grid;
  grid-template-columns: 1fr 80px;
}

.InviteByEmailButton {
  margin-left: 10px;
  margin-right: 10px;
}

.InviteError {
  color: red;
  margin-top: 10px;
}

.InviteMessage {
  margin-top: 10px;
}

.Link {
  cursor: pointer;
  text-decoration: underline;
}

.ListenControls {
  flex-shrink: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.ListenControlButton {
  font-size: 32px;
  cursor: pointer;
  margin-right: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.ListenControlButton:hover {
  background: #666;
}

.ListenHeader {
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #444;
  border-bottom: 1px solid #888;
  border-top: 1px solid #888;
}

.ListenHeaderText {
  overflow: hidden;
  color: #aaa;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.ListenHeaderName {
  font-weight: bold;
  font-size: large;
  color: #fff;
  margin-right: 15px;
}

.ListenHeaderTopic {
  color: #aaa;
  font-size: medium;
}

.ListenPage {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  overflow: hidden;
  background: #222;
}

.Main {
  display: grid;
  grid-template-rows: [content] 1fr [player] min-content;
  height: 100vh;
}

.MainButtons {
  grid-column: 2;
  display: flex;
  place-self: center;
  margin-right: 20px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.MainContent {
  position: relative;
  display: grid;
  grid-template-rows: [tabbar] min-content [tab] 1fr;
  overflow: hidden;
}

.MainContentCover {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.6;
  visibility: hidden;
  z-index: 5;
}

.MainHeader {
  grid-row: 1;
  background: #333;
  display: grid;
  grid-template-columns: 1fr min-content;
}

.MainTabs {
  grid-column: 1;
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-weight: 600;
}

.MainTab {
  background: #666;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 5px 10px;
  cursor: default;
}

.MainTab:first-child {
  border-left: 1px solid #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.MainTab:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.MainTab.Active {
  background: #999;
}

.MainTab:hover {
  background-color: #226;
  text-decoration: underline;
}

.MainTab:hover.Active {
  background-color: #999;
}

.MainTabContent {
  position: relative;
  overflow: hidden;
}

.MainTabContentScroller {
  overflow: scroll;
}

.Modal {
  position: absolute;
  z-index: 20;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}

.ModalButtons {
  margin-top: 10px;
}

.ModalContent {
  overflow: scroll;
}

.NavLink {
  cursor: pointer;
}

.NavLink:hover {
  text-decoration: underline;
}

.Odd {
  background: #222;
}

.Person {
  position: relative;
  background: #666;
  padding: 4px;
  margin: 5px;
  border-radius: 4px;
}

.PersonEditing {
  padding: 10px;
  margin: 10px;
}

.PersonEditingRemoveButton {
  position: absolute;
  top: -16px;
  right: -15px;
}

.PeopleView {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}

.Player {
  grid-row: 3;
  width: 100vw;
  z-index: 100;
}

.PlayerGrid {
  display: grid;
  grid-template-columns: 1fr 200px;
  border-top: 1px solid #888;
}

.PlayerControls {
  grid-row: 1;
  grid-column: 2;
  padding: 10px;
}

.PlayerProgress {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  height: 12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PlayerProgressBar {
  height: 100%;
  width: 0;
  background: #ccc;
  position: relative;
  top: 9px;
}

.PlayerScrubKnob {
  width: 20px;
  height: 20px;
  background: #aaa;
  border: 1px solid #555;
  position: absolute;
  z-index: 2000;
  border-radius: 10px;
}

.PlayerTrackInfo {
  grid-row: 1;
  grid-column: 1;
  justify-self: left;
  text-align: left;
  padding: 10px;
}

.PlayerVolumeContainer {
  width: 100px;
  height: 30px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  position: relative;
  margin: auto;
}

.PlayerVolumeSlider {
  margin: 0px;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 70px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.PlayerVolumeUp {
  position: absolute;
  top: 50%;
  left: 10px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}

.PopUp {
  max-width: 500px;
  margin: auto;
}

.ProfileAvatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #fff;
}

.ProfileAvatarContainer {
  position: relative;
  width: 32px;
  height: 32px;
}

.ProfileFooter {
  padding: 20px;
}

.ProfileGrid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas:
    "emailLabel email"
    "usernameLabel username"
    "avatarLabel avatar"
    "storageLabel storage";
}

.ProfilePage {
  background: #222;
}

.ProfileRoomsHeader {
  background: #444;
  padding: 10px;
}

.ProfileStatusIndicator {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.ProfileStatusIdle {
  background: yellow;
}

.ProfileStatusRecent {
  background: green;
}

.Queue {
  position: absolute;
  top: 55px;
  right: 3px;
  z-index: 100;
  max-height: 75%;
  max-width: 75%;
  overflow: scroll;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-row-gap: 0;
  grid-column-gap: 0;
  background: #444;
  border: 1px solid #fff;
}

.QueueStatusIcon {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
}

.QueueStatusIcon:first-child {
  background: #555;
}

.QueueTrack {
  padding: 10px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-wrap: none;
  text-overflow: ellipsis;
}

.QueueTrack:nth-child(2) {
  background: #555;
}

.QueueTrackControls {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
}

.QueueTrackControls:nth-child(3) {
  background: #555;
}

.QueueTrackInfo {
}

.RoomAddAdmin {
}

.RoomButtons {
  padding: 20px;
}

.RoomDetailsGrid {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-areas:
    "nameLabel name"
    "topicLabel topic"
    "spinningLabel spinning"
    "ownerLabel owner"
    "adminsLabel admins"
    "membersLabel members"
    "invitesLabel invites";
}

.RoomGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 80px;
}

.RoomGridHeading {
  background: #333;
  padding: 5px;
}

.RoomGridName {
  grid-column: 1;
  text-align: left;
  padding: 5px;
}

.RoomGridTopic {
  grid-colun: 2;
  padding: 5px;
}

.RoomGridMembers {
  grid-column: 3;
  padding: 5px;
}

.RoomInviteAcceptButton {

}

.RoomInviteDeclineButton {
  margin-left: 10px;
}

.RoomInviteGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 150px;
}

.RoomInviteGridDiv {
  padding: 3px;
}

.RoomInviteHeader {
  background: #444;
  padding: 3px;
  text-align: left;
}

.RoomInvites {
  margin-top: 15px;
}

.RoomItem {
  background: #444;
  border-bottom: 1px solid #aaa;
  padding: 10px;
  width: 200px;
  cursor: pointer;
}

.RoomItem:hover {
  background: #666;
}

.RoomList {
  position: absolute;
  top: 55px;
  right: 3px;
  border: 1px solid #fff;
  z-index: 100;
  max-height: 75%;
}

.RoomMemberLine {
  display: flex;
  padding: 10px;
}

.RoomMemberLineUsername {
  margin-left: 10px;
}

.RoomMemberList {
  background: #000;
  border: 1px solid #fff;
  position: absolute;
  top: 50px;
  right: 3px;
  z-index: 50;
  min-width: 200px;
  max-height: 75%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.RoomMemberListHeader {
  font-weight: bold;
}

.RoomMemberSummary {
  display: flex;
  margin-right: 20px;
}

.RoomMemberSummaryCount {
  margin-left: 5px;
}

.Rooms {
  padding: 20px;
}

.SearchPage {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  overflow: hidden;
  background: #222;
}

.SearchParams {
  grid-row: 1;
  border-bottom: 1px solid #888;
}

.SearchParamsGrid {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-areas:
    "collectionLabel collection"
    "searchByLabel searchBy"
    "tagsLabel tags"
    "buttonsLabel buttons";
}

.SearchResult {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.SearchResults {
  grid-row: 2;
  height: 100%;
  overflow: scroll;
  border-bottom: 1px solid #888;
  scrollbar-width: auto;  /* Firefox */
  scrollbar-color: #666 #000;
}

.SearchResults::-webkit-scrollbar:vertical {
  display: block;  /* Safari and Chrome */
}

.SearchTextInput {
}

.SearchTypeSelect {
  margin-right: 10px;
}

.Sheet {
  display: grid;
  grid-template-rows: min-content 1fr;
  position: absolute;
  bottom: -1000px;
  z-index: 10;
  background: #222;
  width: 100%;
  height: 100%;
  border-top: 1px solid #666;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: bottom 0.2s linear;
}

.SheetContent {
  grid-column: 1;
  grid-row: 2;
  overflow: scroll;
}

.SheetControls {
  position: absolute;
  top: 8px;
  right: 5px;
}

.SheetInstructions {
  padding: 10px;
}

.SheetInstructionsSpan {
  line-height: 36px;
}

.SheetTitle {
  background: #444;
  height: 35px;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  font-size: large;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  grid-column: 1;
  grid-row: 1;
}

.Tag {
  position: relative;
  background: #666;
  padding: 4px;
  margin: 5px;
  border-radius: 4px;
}

.TagEditing {
  padding: 10px;
  margin: 10px;
}

.TagEditingRemoveButton {
  position: absolute;
  top: -16px;
  right: -15px;
}

.TagView {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}

.TagViewInput {
  vertical-align: top;
}

.Terminal {
  width: 100%;
  text-align: left;
}

.TerminalContainer {
  padding: 10px;
  background: #222;
  grid-row: 1;
  overflow: scroll;
  height: 100%;
  scrollbar-width: auto;  /* Firefox */
  scrollbar-color: #666 #000;
}

.TerminalContainer::-webkit-scrollbar {
  display: block;  /* Safari and Chrome */
}

.TerminalInput {
  background: #444;
  color: #fff;
  resize: none;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: monospace;
}

.TerminalInput:focus{
  outline: none;
}

.TerminalInputContainer {
  grid-row: 2;
  border: 1px solid #888;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  background: #444;
  box-sizing: border-box;
}

.TerminalOutputContainer {
  border: 1px solid #888;
  padding: 2px;
  background: #000;
}

.Toast {
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  display: inline-block;
  z-index: 1000;
  top: 30px;
  justify-self: center;
}

.Toast.show {
  visibility: visible;
  animation: fadein 0.5s;
}

.ToastContent {
  grid-column: 1;
  place-self: center;
}

.ToastControls {
  grid-column: 2;
  place-self: center;
}

.ToastInterior {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-gap: 10px;
}

.TrackControlButton {
  font-size: 32px;
  cursor: pointer;
  margin-right: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.TrackControlButtons {
  display: flex;
  flex-direction: row;
}

.TrackControlButton:hover {
  background: #666;
}

.TrackControlButton:active {
  background: #ccc;
}

.TrackGrid {
  display: grid;
  grid-template-columns: 130px 2fr;
  grid-template-areas:
    "titleLabel title"
    "artistLabel artist"
    "albumLabel album"
    "trackLabel track"
    "dateLabel date"
    "composerLabel composer"
    "genreLabel genre"
    "tagsLabel tags"
    "buttonsLabel buttons"
    "mediaInfoLabel mediaInfoLabel"
    "sizeLabel size"
    "durationLabel duration"
    "codecLabel codec"
    "bitRateLabel bitRate"
    "uploadDateLabel uploadDate"
    "contributorsLabel contributors";
}

.TrackArtist {
  text-overflow: ellipsis;
}

.TrackLink {
  cursor: pointer;
  font-style: italic;
}

.TrackLink:hover {
  text-decoration: underline;
}

.TrackSeparator {
  margin-left: 10px;
  margin-right: 10px;
}

.TrackTitle {
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
  text-overflow: ellipsis;
}

.TrackTitle:hover {
  text-decoration: underline;
}

.UploadPage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}
